import { Env } from './interface';

export const env: Env = {
  //MtaniumMusersManagement
  baseUrl: 'https://g79e85tiy4.execute-api.ap-southeast-1.amazonaws.com/prod',
  // MtaniumEnterprisesManagement
  enterpriseBaseUrl:
    'https://24gr7lbele.execute-api.ap-southeast-1.amazonaws.com/prod',
  // MtaniumEnterprisesSignUrl
  enterpriseRegBaseUrl:
    'https://w2tm34akxk.execute-api.ap-southeast-1.amazonaws.com/prod',
  // MtaniumEnterpriseApps
  enterpriseAppBaseUrl:
    'https://86r52rllhl.execute-api.ap-southeast-1.amazonaws.com/prod',
  // MtaniumEnterprisesPolicies
  policyBaseUrl:
    'https://hgsb7p8xv9.execute-api.ap-southeast-1.amazonaws.com/prod',
  //MtaniumEnterpriseDevices
  deviceBaseUrl:
    'https://v78t8921f1.execute-api.ap-southeast-1.amazonaws.com/prod',
  apiVersion: 'api/v1',
  environment: 'prod',
  isDev: false,
  recaptchaSiteKey: '6LeBDvYUAAAAAFt0FKDBAXxaAj3DaBMvzlNhPisa',
};
